
.infoText,
.infoTextForTable,
.infoTextForChart{
  margin:0;
  font-size:0.75rem;
}

.infoTextForTable{
font-weight: initial;
}
.infoTextTitle,
.infoTextTitleForTable,
.infoTextTitleForChart{
  font-weight: bold;
}
.infoIcon,
.infoIconForChart{
  font-size: 1.2em;
  color:rgb(117,117,117);
  margin:0;
}

.infoIcon:hover,
.infoIconForChart:hover{
  cursor: pointer;
  color:black;
}

.container,
.containerForChart{
  position:absolute;
  top: 4em;
  right: 2em;
  max-width: 200px;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  padding: 5px;
  height: auto;
}

.containerForTable{
  position:absolute;
  right: 1rem;
  bottom: 0;
  left: 1rem;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  padding: 5px;
  height: auto;
}

.containerForTable{
  margin-top: 1em;
  margin-bottom:1em;
}

@media(min-width:400px){
  .infoIcon,
  .infoIconForChart{
    font-size: 1.6em;
  }
  .container,
  .containerForChart{
    top:3em;
    right: 2em;
    max-width: 250px;

  }
}
@media(min-width:500px){
  .container,
  .containerForChart{
    right: 1.5em;
  }
}

@media(min-width:800px){
  .containerForTable{
    left:0;
  }
}
@media (min-width: 1261px) and (max-width: 1499px) {
  .infoIcon,
  .infoIconForChart{
    top:calc(1.25rem + 72.4px)
  }
  .container,
.containerForChart{
  top:calc(3rem + 72.4px);
  right:0.5em;
}
.containerForTable{
  right:0;
}
}
@media (min-width: 1500px) {
  .infoIcon,
  .infoIconForChart{
    top:calc(1.25rem + 72.4px)
  }
  .container,
.containerForChart{
  top:calc(3rem + 72.4px);
  right:1rem;
}
.containerForTable{
  left:1rem;
}
}