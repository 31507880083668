.carousel .control-next.control-arrow:before{
  border-left: 12px solid rgba(255, 255, 255,1);
}
.carousel .control-prev.control-arrow:before {
  border-right: 12px solid rgba(255, 255, 255, 1);
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  display: inline-block;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  content: '';
}
.carousel.carousel-slider .control-arrow{
padding:8px;
}
.carousel.carousel-slider .control-arrow:hover{
opacity:1;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}