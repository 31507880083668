body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.accessibilityDivNotShown{
position:absolute;
top:-9999px;
left: -9999px;
}

.wheelContainer{
  position: absolute;
  display: block;
  background-color: white;
  top: 65px;
  left: 0;
  height: calc(100% - 65px);
  width: 100%;
  z-index: 1001;
}
.first_goal_active {
  opacity: 1 !important;
}

.app_container {
  display: grid;
  height: 100vh;
  grid-template-columns: auto;
  grid-template-rows: 65px 1em 150px 1fr;
  grid-template-areas:
    "nav nav nav"
    ". . ."
    "description description description"
    "container container container"
    "footer footer footer";
}
.navigation_bar {
  grid-area: nav;
  z-index: 1002;
  position:fixed;
  width: 100%;
}
.content_container {
  position: relative; 
  height: calc(100vh - 150px - 65px - 64px + 2em + 32px);
  padding: 0 1em 1em 1em;
  grid-area: container;
}
.footer_container{
  grid-area: footer;
}
.description_sidebar {
  margin: 0 1em;
  min-width: 90%;
  grid-area: description;
}
.legend_sidebar {
  display: none;
  position: absolute;
  top: 65px;
  left: 0;
  height: calc(100% - 65px);
  width: 100%;
  z-index: 1000;
}

.open_mobile_legend_sidebar {
  display: block;
}
.white_background{
  background-color: white;
  position:absolute;
  top:65px;
  left:0;
  z-index: 1001;
  height: 100vh;
  width: 100vw;
}
.message_container {
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1001;
    transform: translate(-webkit-calc(-50%), -webkit-calc(-50% - 32.5px));
}
.error {
  background-color: #ffbaba;
  border: 1px solid red;
}

.loading_text,
.error_text{
  margin:0;
  text-align: center;
}
.loading_text {
  color: #024e91;
  font-size: 1.5em;
}
.error_text {
  font-size: 2em;
  color: red;
}
@media (min-width: 1500px) {
  .app_container {
    display: grid;
    height: 100vh;
    grid-template-columns: minmax(200px, 350px) 1fr minmax(200px, 350px);
    grid-template-rows: 65px 1em calc(100% - 65px - 1em - 52.4px);
    grid-template-areas:
      "nav nav nav"
      ". . ."
      "legend contentBox description"
      "footer footer footer";
  }
  .navigation_bar {
    grid-area: nav;
  }
  .footer_container{
    grid-area: footer;
  }
  .legend_sidebar {
    display: block;
    grid-area: legend;
    top: calc(65px + 1em);
    left: 1em;
    width: calc(350px - 2em);
    height: calc(100% - 65px - 32px - 52.4px);
  }
  .content_container {
    height: calc(100vh - 65px - 32px - (60.63px + 16px) + 77px - 52.4px);
    grid-area: contentBox;
  }
  .description_sidebar {
    height: calc(100vh - 65px - 32px - (101.6px - 1.5em) + 77px - 52.4px);
    grid-area: description;
    margin: 0 1em 0 0;
  }
  .mobile_legend_sidebar {
    display: none;
  }
}
.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}
@media (min-width: 1261px) and (max-width: 1499px) {
  .app_container {
    display: grid;
    height: 100vh;
    grid-template-columns: minmax(150px, 300px) 1fr minmax(200px, 300px);
    grid-template-rows: 65px 1em calc(100% - 65px - 1em - 52.4px);
    grid-template-areas:
      "nav nav nav"
      ". . ."
      "legend contentBox description"
      "footer footer footer";
  }
  .navigation_bar {
    grid-area: nav;
  }
  .legend_sidebar {
    display: block;
    grid-area: legend;
    top: calc(65px + 1em);
    left: 1em;
    width: calc(300px - 2em);
    height: calc(100% - 65px - 32px - 52.4px);
  }
  .footer_container{
    grid-area: footer;
  }
  .content_container {
    height: calc(100vh - 65px - 32px - (101.6px - 1.5em) + 77px - 52.4px);
    grid-area: contentBox;
    padding:0 0 1em 0;
    width: 100%;
  }
  .description_sidebar {
    grid-area: description;
    margin: 0 1em 0 0;
    height: calc(100vh - 65px - 32px - (101.6px - 1.5em) + 77px - 52.4px);

  }
  .mobile_legend_sidebar {
    display: none;
  }
}
@media (min-width: 801px) and (max-width: 1260.99px) {
  .app_container {
    display: grid;
    height: 100vh;
    grid-template-columns: minmax(150px, 300px) 1fr;
    grid-template-rows: 65px 1em minmax(150px, 200px) 1fr;
    grid-template-areas:
      "nav nav"
      ". ."
      "legend description"
      "legend contentBox"
      "footer footer";
  }
  .navigation_bar {
    grid-area: nav;
  }
  .content_container {
    grid-area: contentBox;
    padding:0 1em 1em 0;
    height: calc(100vh - 150px - 65px - 64px + 2em - 52.4px);
  }
  .footer_container{
    grid-area: footer;
  }
  .legend_sidebar {
    display: block;
    grid-area: legend;
    top: calc(65px + 1em);
    left: 1em;
    width: calc(300px - 2em);
    height: calc(100% - 65px - 32px - 52.4px);
  }

  .description_sidebar {
    margin: 0 1em 0 0;
    min-width: 90%;
    grid-area: description;
  }
  .mobile_legend_sidebar {
    display: none;
  }
}
@media (min-width:500px){
body,html{
  overflow-y: hidden;
}
}
