.icon{
  z-index:1000;
  width:100%;
  max-width:30px;
  margin-right: 1em;
 filter: invert(48%) sepia(0%) saturate(1338%) hue-rotate(137deg) brightness(95%) contrast(98%);
}
.icon:hover{
  cursor: pointer;
  filter:none;
}

.infoText{
  margin:0;
  font-size:0.75rem;
}

.infoTextTitle{
  font-weight: bold;
}

.container{
  position:absolute;
  top: 4em;
  right: 5.5em;
  max-width: 180px;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  padding: 5px;
  height: auto;
 
}

@media(min-width:400px){
  .container{
    top:3em;
    right: 5em;
    max-width: 250px;
  }
  .icon{
    max-width:40px;
  }
}
@media(min-width:500px){
  .icon{
    max-width:50px;
  }
}
@media(min-width:1261px){
  .container{
    top:calc(3rem + 72.4px);
    right: 4em;
  }
}
