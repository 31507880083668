.title {
  margin: 0;
}
.container {
  display: flex;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-wrap: wrap;
  background-color: white;
}

.indicator {
  width: 35%;
  flex-grow: 1;
  opacity: 0.4;
  transform: scale(1);
  transition: transform 250ms, opacity 250ms;
}

.indicator:hover,
.indicator:focus {
  transform: scale(1.05);
  z-index: 2;
  opacity: 1;
  cursor: pointer;
  /* outline: none; */
}

.indicator_active,
.first_goal_active {
  opacity: 1;
}
.indicator.global_goals_icon {
  opacity: 1;
}
.indicator.global_goals_icon:hover {
  transform: scale(1);
}
.disactive{
  opacity :0.2;
  pointer-events: none;
  filter:grayscale(90%)
}
.disactive:hover{
  transform: scale(1);
}
@media (min-width: 715px) and (max-width: 800px) {
  .container > .indicator {
    width: 30%;
  }
}
