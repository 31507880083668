.container{
  max-height: 100%;
}
.logo_img,
.textLogo_img {
  max-width:80%;
  max-height: 50px;
  cursor: pointer;
}
.logo_img{
  max-width:30px;
}
.textLogo_img{
  max-width:75px;
  margin-left:0.5em;
}

@media(min-width:320px){

.textLogo_img{
  max-width: 110px;
}
.logo_img{
  max-width: 40px;
}
}
@media(min-width:430px){
  .logoTitle{
    font-size: 1rem;
  }
  .logo_img,
  .textLogo_img{
    max-width:100%
  }
}
@media(min-width:801px){
  .textLogo_img{
    margin-left:1em;
  }
}
