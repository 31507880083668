.leaflet-container {
  width: 100%;
  height:calc(100% - 8rem);
  background:white;
  font-family: inherit;
}
.leaflet-container a{
  color:black;
  font-weight: bold;
}
.message_container {
  padding: 1em;
  border-radius: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  max-width:250px;
  transform: translate(-50%, -50%);
}
.error {
  background-color: #ffbaba;
  border: 1px solid red;
}
.loading_text,
.error_text{
  text-align: center;
}
.loading_text {
  color: #024e91;
  font-size: 1.5em;
}
.error_text {
  font-size: 2em;
  color: red;
}
.tooltip{
  padding:0.5em;
}
.infoBtn{
  z-index: 1000;
}

@media (min-width: 1261px){
  .leaflet-container{
    height: calc(100% - 57px - 77px - 4.5rem);
  }
}
