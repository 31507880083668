.wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
}

.img_text_contianer{
  margin:auto;
}
.goalIcon{
  display: block;
  width: 90vw;
  max-width: 500px;
  transform: scale(0.95);
  transition: transform 250ms;
  margin: auto;
}
.goalIcon:hover{
  cursor: pointer;
  transform: scale(1);
}
.textContainer{
  margin:auto;
  max-width: 1200px;
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
}
.introText{
text-align: left;
font-size:0.8em;
margin-bottom: 3em;
}
.logoIcon{
  text-align: center;
  width: 100%;
  max-width: 200px;
}

.exploreBtn{
  font-size:1.25em;
  text-align: center;
  color:white;
  background-color:rgb(2,78,145) ;
  max-width:200px;
  padding:1em 2em;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  align-self:center;
}
.exploreBtn:hover,
.exploreBtn:focus{
  background: rgba(0, 157, 219, 255);
  cursor:pointer;
  transform: scale(1.1);
  transition: all 250ms;
}

.genova_img{
  width:100vw;
}

@media(min-width:500px){
  .textContainer{
    max-width:450px;
    flex-direction: row;
  }
  .introText{
    margin-right: 2em;
    margin-bottom:1em;
  }
}
@media(min-width:800px){
  .img_text_contianer{
    display: flex;
    flex-direction: row;

  }
  .wrapper{
    margin-top: 0;
    justify-content: center;
    align-items: center;
    overflow-y:hidden;
  }
  .textContainer{
    flex-direction: column;
  }
  .goalIcon{
    max-width: 550px;
    width: 70vw;
    align-self:flex-start;
  }
  .introText{
    font-size:1em;
    margin: 2em 0 4em 0;
  }
  .exploreBtn{
    align-self: flex-start;
  }
}
@media(min-width:1800px){
  .goalIcon{
    max-width: 700px; 
  }
}