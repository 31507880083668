.nav_bar {
  border-radius: 7px 7px 0 0;
  background-color: white;
  padding: 1em 0;
  font-size: 0.55em;
}
.nav_list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 1em 0;
  align-items: center;
}
.list_item {
  position: relative;
  margin: 0 0 0 1em;
  padding-bottom: 0.2em;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  font-weight: bold;
  letter-spacing: 1px;
}
.list_item:not(:first-child) {
  margin: 0 0 0 1em;
}
.list_item:hover {
  cursor: pointer;
}
.list_item_active {
  border-bottom: 1.5px solid rgb(0, 0, 0);
}
.list_item::after {
  content: "";
  display: block;
  height: 0.5px;
  background: rgb(0, 0, 0);
  opacity: 1;
  transition: width 500ms;
  width: 0;
  visibility: hidden;
  position: absolute;
  top:13.5px;
  left: 0;
}
.list_item:hover::after,
.list_item:focus::after {
  visibility: visible;
  width: 100%;
}
  .download_icon{
    font-size: 1.5em;
    filter: invert(47%) sepia(18%) saturate(0%) hue-rotate(283deg) brightness(94%) contrast(98%);
    max-width: 16px;
}
  .download_icon:hover{
    cursor: pointer;
    filter:none;
    }
    
@media (min-width: 400px) {
  .nav_bar {
    font-size: 0.8em;
  }
  .nav_list{
    margin:0;
  }
  .list_item::after {
    height: 1px;
    top:19px;
  }
}
@media (min-width:500px){
  .nav_bar {
    font-size: 1em;
  }
  .list_item::after {
    top:24px;
  }
  .download_icon{
    max-width: 24px;
}
}
