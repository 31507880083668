.footer{
  font-size: 0.65em;
  text-align: center;
  padding:0.7rem;
  background-color:rgb(240, 240, 240);
}
.mail_link{
  color:black;
  text-decoration: none;
  border-bottom: 1px solid rgb(170, 170, 170);
}
.mail_link:hover,
.mail_link:focus{
background-color: rgb(223, 223, 223);
border-bottom: 1px solid black;
}
.logo_icon{
  width: 100%;
  max-width:45px;
  height: auto;
}
.logo_link{
  margin-left: 0.25em;
}
@media(min-width:800px){
  .footer{
    font-size: 0.7em;
    height: 30px;
  }
}