
.description {
  font-size: 0.8em;
  margin:0;
  background-color: white;
  padding: 0.5rem 1rem;
  color: black;
  max-width: calc(75% - 6px);
  min-width: calc(75% - 6px);
  border:3px solid white;
}

.description:hover,
.description:focus,
.description:active {
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.description:last-child {
  margin-bottom: 1em;
}

.goal_1{
  background-color: #DF2538;border:3px solid #DF2538;color:white;
}
.goal_1_active{
  border:3px solid #DF2538;
}
.goal_2{
  background-color: #E1AB31;border:3px solid #E1AB31;color:white;
}
.goal_2_active{
  border:3px solid #E1AB31
}
.goal_3{
  background-color: #4E9B3C;border:3px solid #4E9B3C;color:white;
}
.goal_3_active{
  border:3px solid #4E9B3C
}
.goal_4{
  background-color: #C5272F;border:3px solid #C5272F;color:white;
}
.goal_4_active{
  border:3px solid #C5272F
}
.goal_5{
  background-color: #E63A2B;border:3px solid #E63A2B;color:white;
}
.goal_5_active{
  border:3px solid #E63A2B
}
.goal_6{
  background-color: #3FBBE3;border:3px solid #3FBBE3;color:white;
}
.goal_6_active{
  border:3px solid #3FBBE3
}
.goal_7{
  background-color: #F9C504;border:3px solid #F9C504;color:white;
}
.goal_7_active{
  border:3px solid #F9C504
}
.goal_8{
  background-color: #A72843;border:3px solid #A72843;color:white;
}
.goal_8_active{
  border:3px solid #A72843
}
.goal_9{
  background-color: #EB632C;border:3px solid #EB632C;color:white;
}
.goal_9_active{
  border:3px solid #EB632C
}
.goal_10{
  background-color: #DD1D7B;border:3px solid #DD1D7B;color:white;
}
.goal_10_active{
  border:3px solid #DD1D7B
}
.goal_11{
  background-color: #F59D21;border:3px solid #F59D21;color:white;
}
.goal_11_active{
  border:3px solid #F59D21
}
.goal_12{
  background-color: #C58E24;border:3px solid #C58E24;color:white;
}
.goal_12_active{
  border:3px solid #C58E24
}
.goal_13{
  background-color: #477F43;border:3px solid #477F43;color:white;
}
.goal_13_active{
  border:3px solid #477F43
}
.goal_14{
  background-color: #2597D3;border:3px solid #2597D3;color:white;
}
.goal_14_active{
  border:3px solid #2597D3
}
.goal_15{
  background-color: #60B238;border:3px solid #60B238;color:white;
}
.goal_15_active{
  border:3px solid #60B238
}
.goal_16{
  background-color: #17669D;border:3px solid #17669D;color:white;
}
.goal_16_active{
  border:3px solid #17669D
}
.goal_17{
  background-color: #2B4B6C;border:3px solid #2B4B6C;color:white;
}
.goal_17_active{
  border:3px solid #2B4B6C
}
.goal_18{
  background-color: #00d400;border:3px solid #00d400;color:white;
}
.goal_18_active{
  border:3px solid #00d400
}





