.nav_list {
  display: flex;
  letter-spacing: 0.5px;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #024e91;
  align-items: center;
  justify-content: space-between;
  height: 65px;
}
.nav_list > * {
  margin: 1em;
}
.nav_list > a:not(:first-of-type){
  display: none;
}

.nav_items{
  display: flex;
  flex-direction:column;
  align-items: center;
  position:absolute;
  top:65px;
  left:0;
  z-index: 100;
  background-color: #024e91;
  margin:0;
  height:-webkit-calc(100vh - 65px);
  width:100vw;
  transform: translateX(100%);
  transition: transform 250ms;
}
.nav_items_active{
  transform: translateX(0);
}
.nav_link{
  display: flex;
  width:100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5em;
  margin:0;
  text-decoration: none;
  color:white;
}
.nav_link_active{
  background-color: rgba(0, 157, 219, 255);
}

.icons_mobile{
  margin-left: auto;
}
.icon {
  font-size: 25px;
  color: ivory;
  border-radius: 50%;
  padding: 0.2em;
  min-width: 1em;
}
.icon:first-child{
  margin-right:0.2em;
}
.nav_link:hover,
.icon:hover {
  cursor: pointer;
  background-color: rgba(0, 157, 219, 255);
  transition: background-color 250ms;
}

.logo_wrapper > li{
padding: 0;
}
.logo_wrapper > li:hover{
  cursor: initial;
  background-color: transparent;
}
.logo_wrapper a{
  padding: 0;
  border-radius: 50%;
}
.logo_wrapper a:hover{
  background-color: transparent;
}
@media(min-width:320px){
  .icon {
    font-size: 30px;
  }
}
@media (min-width: 800px) {
  .icons_mobile {
    display: none;
  }
  .nav_items{
    position:initial;
    flex-direction: row;
    width: initial;
    margin:0;
    height: 100%;
    transform: translateX(0);
    width: 57%;
    max-width:600px;
  }
  .nav_link{
     padding:0 1em;
     font-size: 0.9em;
     text-align: center;
     justify-content: center;
  }
}
