.container{
  padding: 0 0.5em 0.5em 0.5em ;
  text-align: center;
  display: flex;
  max-height: 100px;
  width: 88.5%;
  margin:0 auto;
  margin-bottom:1em;
}
.arrow_group{
width:100%;
}
.arrow_group_title{
  font-size:0.8em;
  font-weight: bold;
  margin:0 0 0.5em 0;
  font-style: italic;
}
.containerTitle{
  font-size: 0.9em;
  margin: 0 auto 0.5em auto;
  font-weight: bold;
}

.containerTitleNotMobile{
  display: none;
}
.noDataText{
  margin:0 0 0.5em 0;
  font-size:0.7em;
  font-style: italic;
}
.arrows_container{
  display: flex;
  flex-direction: column;

}
.arrow_container{
display: flex;
justify-content: center;
}
.arrow_title{
  font-size: .75em;
  margin:.2em;
  width:50%;
}

.arrow_rotate_0{
  transform: rotate(0);
}
.arrow_rotate_45{
  transform: rotate(45deg);
}
.arrow_rotate_135{
  transform: rotate(135deg);
}
.arrow_rotate_180{
  transform: rotate(180deg);
}
.green_arrow,
.red_arrow{
  font-size: 0.9em;
}
.green_arrow{
  color: green;
}
.red_arrow{
  color:red;
}

.title_1{
  color:#6aa84f;
}
.title_2{
  color:#f1c232;
}
.title_3{
  color:#4bc9ff;
}
.title_4{
  color:#0b4e8a;
}
.title_5{
  color:#cc0000;
}
@media (min-width:500px){
.container{
  flex-direction: column;
  margin-bottom:0;
  width: 100px;
  max-height: 100%;
}
.arrow_group_title{
  font-size:1em;
  margin:0 0 0.5em 0;
}
.containerTitle{
  font-size:1.05em;
}
.containerTitleMobile{
display: none;
}
.containerTitleNotMobile{
  display: block;
}
.noDataText{
  font-size:0.9em;
}
.arrow_title{
  font-size: .7em;
  margin:.5em;
  width:100%;
}
.arrow_group:first-child{
  padding-bottom:1em;
}
.arrow_container{
  flex-direction: column;
  align-items: center;
  }
}
@media (min-width:500px) and (max-width:1260px){
  .arrow_group_title{
    font-size:0.85em;
    margin:0 0 0.5em 0;
  }
  .containerTitle{
    font-size:0.95em;
  }
  .noDataText{
    font-size:0.75em;
  }
}
